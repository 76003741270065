<template>
  <el-dialog
      title="添加渠道"
      width="35%"
      :visible.sync="dialogState"
      :modal-append-to-body="false"
      @close="close"
      center>
    <el-form label-width="100px" :model="form" :rules="rules" style="margin-left: 20%" ref="form">
      <el-row>
        <el-col :span="15">
          <el-form-item label="渠道等级" prop="sourceType">
            <el-select v-model="form.sourceType" placeholder="渠道等级">
              <el-option label="一级渠道" def :value="1"/>
              <el-option label="二级渠道" :value="2"/>
              <el-option label="三级渠道" :value="3"/>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="15">
          <el-form-item label="父级渠道" prop="parentId" v-if="form.sourceType===2">
            <el-select v-model="form.parentId" placeholder="父级渠道">
              <el-option
                  v-for="item in parentIdArray"
                  :key="item.value"
                  :label="item.name"
                  :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="15">
          <el-form-item label="渠道名称" prop="sourceName">
            <el-input v-model="form.sourceName"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="addSourceCheck()">提 交</el-button>
        <el-button @click="close">取 消</el-button>
      </span>
  </el-dialog>
</template>

<script>
export default {
  name: "source-add",
  data() {
    return {
      form: {
        //公司名
        sourceType: 1,
        parentId: "",
        sourceName: "",
        tenantCrop: localStorage.getItem("tenantCrop"),
      },
      rules: {
        sourceType: [
          {required: true, message: '请选择渠道类型', trigger: 'change'},
        ],
        parentId: [
          {required: true, message: '请选择父级渠道', trigger: 'change'},
        ],
        sourceName: [
          {required: true, message: '请输入渠道名称', trigger: 'blur'},
        ],
      },
      parentIdArray:[],
      dialogState: this.state,
    }
  },
  props: {
    state: {
      type: Boolean,
      required: true,
      default: false,
    }
  },
  created() {
    this.querySourceOneLevelIds();
  },
  methods: {
    //检查订单校验
    addSourceCheck: function () {
      this.$refs['form'].validate((valid) => {
        if (valid) {
          this.addSource();
        }
      })
    },
    //添加店铺
    addSource: function () {
      let value = "是否添加该渠道?"
      this.$confirm(value, '添加渠道:', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
      }).then(() => {
        this.$axios({
          method: "POST",
          url: "/source/addSource",
          params: this.form
        }).then(response => {
          if (response.data.code === 200) {
            this.$message.success("添加成功!")
            let self = this;
            setTimeout(function () {
              self.close()
            }, 1500)
          } else {
            this.$message.error(response.data.msg)
          }
        });
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消'
        });
      });
    },
    //查询所有一级渠道
    querySourceOneLevelIds(){
      this.$selectUtils.querySourceOneLevelIds().then(response=>{
        this.parentIdArray=JSON.parse(response.data.data);
      })
    },

    close: function () {
      this.$emit("close", false);
    }
  }
}
</script>

<style scoped>

</style>